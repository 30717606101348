import { Box, CircularProgress, Container } from "@mui/material"
import banner from "../../assets/img/quizDownload.jpeg"
import React, { useState } from "react"
import { Helmet } from "react-helmet"

const BASE_URL = import.meta.env.VITE_BASE_URL
const AppPage = () => {
  const handleDownload = () => {
    try {
      const iframe = document.createElement("iframe")
      iframe.style.display = "none"
      iframe.src = `${BASE_URL}api/download/file?path=public/quizmaster.apk`
      document.body.appendChild(iframe)

      iframe.onload = () => {
        setTimeout(() => {
          document.body.removeChild(iframe)
        }, 500)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <Container
        sx={{
          width: "100%",
          maxWidth: "unset !important",
          background:
            "linear-gradient(183.57deg, rgba(255, 155, 78, 0.9) 2.93%, rgba(252, 183, 85, 0.9) 33.26%, rgba(248, 214, 93, 0.9) 55.9%, rgba(255, 255, 255, 0.9) 89.85%)",
          padding: { xs: "20px", md: "100px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: { xs: "80%", md: "30%" } }}>
          <img
            src={banner}
            alt="Logo"
            style={{ width: "100%", borderRadius: "20px", cursor: "pointer" }}
            onClick={() => {
              handleDownload()
            }}
          />
        </Box>
      </Container>
    </div>
  )
}

export default AppPage
